<template>
    <div class="Femtcad">
        <p class="p justify">
            FemTCAD可用于半导体器件输运性质计算，基于有限元和非平衡格林函数方法，可以通过材料的基本物性参数计算特殊形状结构的器件的输运性质。
        </p>
    </div>
</template>

<script>
export default {
  name: 'Femtcad',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', 'FemTCAD')
      }
}
</script>
<style lang="less" scoped>
.Femtcad{
    margin-bottom:6.25rem;
}
</style>
